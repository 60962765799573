
import { defineComponent } from "vue";
import TableWidget9 from "@/components/widgets/tables/Widget9.vue";
import ListWidget5 from "@/components/widgets/lists/Widget5.vue";
import MixedWidget2 from "@/components/widgets/mixed/Widget2.vue";
import MixedWidget7 from "@/components/widgets/mixed/Widget7.vue";
import MixedWidget10 from "@/components/widgets/mixed/Widget10.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "dashboard",
  components: {
    TableWidget9,
    ListWidget5,
    MixedWidget2,
    MixedWidget7,
    MixedWidget10,
  },
  data(){
    return {
      users: [],
      activity: []
    }
  },
  methods: {
    async fetchusers(){
      try{
        let res = await this.axios.get('/activity')
        this.activity = res.data.activity
      }catch(e){
        try{
          if(e.response.status == 401){
            this.$router.replace('/sign-in')
          }else{
            // useToast().warning(e.response.data.message)
          }
        }catch(err){
          // useToast().warning(e.message)
        }
      }
    }
  },
  asyncData(){
    this.fetchusers()
  },
  mounted(){
    setCurrentPageTitle("Dashboard");
  }
});
