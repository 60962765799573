
import { defineComponent, ref } from "vue";
import firebase from 'firebase'

export default defineComponent({
  name: "kt-widget-9",
  components: {},
  props: {
    widgetClasses: String,
    users: Array,
    count: Number
  },
  data(){
    return {
      list: [],
      checked: ref(false)
    }
  },
  methods: {
    async fetchusers(){
      try{
        let response = await this.axios.get('/users')
        this.list = response.data.users
      }catch(e){
        console.log(e)
      }
    }
  },
  created(){
    this.fetchusers()
  }
});
